'use client';

import '@/shims';

import type { Client as UrqlClient } from '@urql/core';
import { ssrExchange } from '@urql/core';
import { UrqlProvider, useClient as useUrqlClient } from '@urql/next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import type { FidantSessionContextValue } from '@/auth/client';
import { useSession } from '@/auth/client';
import type { FidantClientSession } from '@/auth/types';
import type { SuspenseOrAuthBoundaryProps } from '@/components/SuspenseOrAuthBoundary';
import { SuspenseOrAuthBoundary } from '@/components/SuspenseOrAuthBoundary';
import type { FidantAPIClient } from '@fidant-io/api-client';
import { AuthenticationError, createConfiguredClient, getBackend } from '@fidant-io/api-client';

interface InnerProps {
  apiUrl: string;
}

// Non-SSR render
const isBrowser = typeof window !== 'undefined';

function FidantAPIClientProviderInner({ apiUrl: url, children }: React.PropsWithChildren<InnerProps>) {
  // Each ssr exchange records any urql requests/responses that occur during rendering.
  // The hydration data is appended to an array on `window[Symbol.for("urql_transport")]`,
  // and hydrate in order and dehydrate in order.
  // Be careful that this *must* not be shared between users; don't put it in a global.
  const ssr = useMemo(() => ssrExchange({ isClient: isBrowser, includeExtensions: true }), []);

  const [error, setError] = useState<Error | null>(null);

  const { showBoundary, resetBoundary } = useErrorBoundary<Error>();

  const session = useSession();

  // This is bound to a ref because in dev mode, I was seeing infinite re-renders when I just had useMemo,
  // even after verifying that all instances were identical. This could have been related to a faulty "suspense"
  // setting, or the re-use of the ssr exchange with the (RSC) server-only client.
  const ref = useRef<{ session: FidantSessionContextValue; client?: FidantAPIClient }>({
    session,
  });
  ref.current.session = session;

  const authPromiseRef = useRef<PromiseWithResolvers<FidantClientSession> & { resolved?: boolean }>(
    Promise.withResolvers<FidantClientSession>()
  );

  useEffect(() => {
    const authRef = authPromiseRef.current;
    if (authRef.resolved) {
      console.debug('SKIPPING RESOLVED AUTH', session);
      return;
    }
    if (session.status === 'unauthenticated') {
      const e = new AuthenticationError();
      console.log('FAILING AUTH PROMISE', session);
      authRef.resolved = true;
      setError(e);
      showBoundary(e);
      authRef.reject(e);
    } else if (session.status === 'authenticated') {
      console.log('RESOLVING AUTH PROMISE', session);
      authRef.resolved = true;
      setError(null);
      resetBoundary();
      authRef.resolve(session.data);
    }
    return () => {
      if (authRef.resolved) {
        return;
      }
      console.log('UNMOUNTING AUTH', session);
      authRef.resolved = true;
      return void authRef.reject(new DOMException('Component unmounted', 'AbortError'));
    };
  }, [session, showBoundary, resetBoundary]);

  const client = useMemo(() => {
    if (ref.current.client) {
      console.log('CLIENT: REUSING GQL', { url, isBrowser });
      return ref.current.client;
    }
    const baseBackend = getBackend({
      url,
      fetch,
      fetchOptions: {
        ...(isBrowser
          ? {}
          : {
              headers: {
                'User-Agent':
                  typeof navigator !== 'undefined'
                    ? `${navigator.userAgent} (React SSR)`
                    : `React SSR ${process.version}`,
              },
            }),
      },
      auth: async () => {
        let session: FidantClientSession;
        try {
          session = await authPromiseRef.current.promise;
        } catch (e) {
          if (e instanceof Error && e.name === 'AbortError') {
            console.warn('CLIENT: AUTH ABORTED', e, { isBrowser });
            return null;
          }
          console.error('CLIENT: AUTH FAILED', e, { isBrowser });
          throw e;
        }
        console.log('CLIENT: AUTHED GQL', { session, isBrowser });
        const sub = session.user.id;
        return { sub, role: 'user' };
      },
    });
    return (ref.current.client = createConfiguredClient({
      suspense: true,
      scalars: 'rsc',
      backend: {
        ...baseBackend,
        exchanges: [ssr, ...baseBackend.exchanges],
      },
    }));
  }, [url, ssr]);

  return (
    <UrqlProvider client={client as UrqlClient} ssr={ssr}>
      {/* Boundary for children *using* the client */}
      <SuspenseOrAuthBoundary name="with-api-client" resetKeys={[client, error]}>
        {children}
      </SuspenseOrAuthBoundary>
    </UrqlProvider>
  );
}

export interface Props extends Omit<SuspenseOrAuthBoundaryProps, 'name'>, InnerProps {}

export function FidantAPIClientProvider({ apiUrl, children, ...props }: React.PropsWithChildren<Props>) {
  // Boundary for auth-like errors, or those propagated from children.
  return (
    <SuspenseOrAuthBoundary name="api-client" {...props}>
      <FidantAPIClientProviderInner apiUrl={apiUrl}>{children}</FidantAPIClientProviderInner>
    </SuspenseOrAuthBoundary>
  );
}

export const useGraphqlClient = () => useUrqlClient() as FidantAPIClient;

export { useMutation, useQuery, useSubscription } from '@urql/next';
